import React from 'react';
import '../styles/Contact.css'; // Ensure you have this CSS file
import { FaLinkedin, FaGithub, FaFacebook } from 'react-icons/fa';

function Contact() {
  return (
    <div className="Contact">
      <h2>Contact</h2>
      <p>If you want to get in touch, you can reach me via email at <a href="mailto:ugrenovikj.stefan@gmail.com" rel="noopener noreferrer" title='Email'>ugrenovikj.stefan@gmail.com</a>.</p>
      <p>Connect with me on social media:</p>
      <div className="social-links">
        <a href="https://www.linkedin.com/in/stefan-ugrenovikj-0a3661189/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" title='LinkedIn'>
          <FaLinkedin className="social-icon" />
        </a>
        <a href="https://github.com/StefanUgrenovikj" target="_blank" rel="noopener noreferrer" aria-label="GitHub" title='GitHub'>
          <FaGithub className="social-icon" />
        </a>
        <a href="https://www.facebook.com/ugro69" target="_blank" rel="noopener noreferrer" aria-label="Facebook" title='Facebook'>
          <FaFacebook className="social-icon" />
        </a>
      </div>
      <p className="call-to-action">Feel free to reach out or connect with me!</p>
    </div>
  );
}

export default Contact;

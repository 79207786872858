import React from 'react';
import '../styles/About.css'; 

function About() {
  return (
    <div className="About">
      <h2>About Me</h2>
      <div className="about-grid">
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <h3>Education</h3>
            </div>
            <div className="card-back">
                <ul>
                    <li>Faculty Of Information And Communication Technologies – University St. Clement of Ohrid University of Bitola (2015-2017)</li>
                    <li>Attendance Diploma - Interworks Education Center (2019)</li>
                </ul>   
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <h3>Working Status</h3>
            </div>
            <div className="card-back">
                <p>Currently working 3 years as a Full-Stack Developer at <a href="https://www.esof.net" target="_blank" rel="noopener noreferrer" title='ESOF'> ESOF</a>.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <h3>Skills</h3>
            </div>
            <div className="card-back">
              <ul>
                <li>JavaScript, TypeScript</li>
                <li>React, Angular, Vue.js</li>
                <li>Node.js, Laravel, Symfony</li>
                <li>Docker, Ansible, MySql, MsSql</li>
                <li>HTML, CSS, Sass</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <h3>Hobbies</h3>
            </div>
            <div className="card-back">
              <ul>
                <li>Reading</li>
                <li>Gaming</li>
                <li>Traveling</li>
                <li>Snurkeling</li>
              </ul>       
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;

import React, { useState, useEffect, useRef } from 'react';
import '../styles/Slider.css';
import eventlokale from '../assets/eventlokale.png';
import someecards from '../assets/someecards.png';
import fitnesstoday from '../assets/fitnesstoday.png';
import portfolio from '../assets/portfolio.png';
import bck from '../assets/bck.png';
import { FaLaravel, FaDocker, FaReact, FaAngular, FaSymfony, FaVuejs, FaNodeJs, FaHtml5, FaCss3 } from "react-icons/fa";
import { SiNuxtdotjs, SiMysql, SiMicrosoftsqlserver } from "react-icons/si";

const projects = [
  {
    title: 'My Portfolio',
    description: ' A personal portfolio showcasing my skills, projects, and achievements. Built with React and Node.js for a seamless user experience.',
    technologies: 'React, Node.js',
    image: portfolio, 
  },
  {
    title: 'Eventlokale',
    description: 'Eventlokale.ch is a swiss-based event management platform that helps small and large companies manage their events.I worked on both the frontend and the backend of this project using Vue, Nuxt, MySql and Laravel.',
    technologies: 'Vue, Nuxt.js, MySQL, Docker, Laravel',
    image: eventlokale, 
  },
  {
    title: 'Someecards',
    description: 'Someecards.com is a website that allows users to create and share their own cards. I worked on the CMS part of the project using Symfony and Angular.',
    technologies: 'Symfony, Angular',
    image: someecards, 
  },
  {
    title: 'BCK Gmbh',
    description: 'With its established product POLYMARK, the company operates a professional research database that enables users to access Europe-wide brand and design information via a search platform. This project I only developed in Laravel making a proccess of parsing,mapping and filtering data from csv file and importing it in  the database.',
    technologies: 'Microsoft SQL Server, Laravel, Docker, MySQL',
    image: bck,
  },
  {
    title: 'FitnessToday',
    description: 'FitnessToday is a swiss-based fitness platform that helps small and large gyms along with some personal trainers,nutritionists and fitness trainers advertise.I worked on both the frontend and the backend of this project using Vue, Nuxt, MySql and Laravel.',
    technologies: 'Microsoft SQL Server, Laravel, Docker, MySQL',
    image: fitnesstoday, 
  },
  // Add more projects as needed
];

const techIconMap = {
  Laravel: <FaLaravel />,
  Docker: <FaDocker />,
  React: <FaReact />,
  Angular: <FaAngular />,
  Symfony: <FaSymfony />,
  Vue: <FaVuejs />,
  'Node.js': <FaNodeJs />,
  HTML: <FaHtml5 />,
  CSS: <FaCss3 />,
  'Nuxt.js': <SiNuxtdotjs />,
  MySQL: <SiMysql />,
  'Microsoft SQL Server': <SiMicrosoftsqlserver />,
};

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const sliderRef = useRef(null);

  const totalSlides = projects.length;
  const firstSlide = projects[0];
  const lastSlide = projects[totalSlides - 1];
  const slides = [lastSlide, ...projects, firstSlide];

  const nextSlide = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex(prevIndex => prevIndex + 1);
  };

  const prevSlide = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex(prevIndex => prevIndex - 1);
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    if (currentIndex === 0) {
      setCurrentIndex(totalSlides);
      sliderRef.current.style.transition = 'none';
      sliderRef.current.style.transform = `translateX(-${totalSlides * 100}%)`;
    } else if (currentIndex === totalSlides + 1) {
      setCurrentIndex(1);
      sliderRef.current.style.transition = 'none';
      sliderRef.current.style.transform = 'translateX(-100%)';
    }
  };

  useEffect(() => {
    if (!isTransitioning) return;
    sliderRef.current.style.transition = 'transform 0.5s ease-in-out';
    sliderRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
  }, [currentIndex, isTransitioning]);

  useEffect(() => {
    if (sliderRef.current && !isTransitioning) {
      sliderRef.current.style.transition = 'transform 0.5s ease-in-out';
    }
  }, [isTransitioning]);

  return (
    <div className="slider">
      <button className="slider-button left" onClick={prevSlide}>
        &#10094;
      </button>
      <div
        className="slider-wrapper"
        ref={sliderRef}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((project, index) => (
          <div className="slider-card" key={index}>
            <div className="slider-content">
              <h3 className="slider-title">{project.title}</h3>
              <p className="slider-description">{project.description}</p>
              <div className="slider-tech">
                {project.technologies.split(', ').map((tech) => (
                  <span key={tech} className="tech-icon">
                    {techIconMap[tech]} {tech}
                  </span>
                ))}
              </div>
            </div>
            <img src={project.image} alt={project.title} title={project.title} className="slider-image" />
          </div>
        ))}
      </div>
      <button className="slider-button right" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
};

export default Slider;

import React from 'react';
import Slider from './Slider'; // Import the Slider component
import '../styles/Projects.css'; // Ensure you create this CSS file for additional styling

function Projects() {
  return (
    <div className="Projects">
      <h2>Projects</h2>
      <Slider />
    </div>
  );
}

export default Projects;

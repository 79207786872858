import React, { useState, useEffect } from 'react';
import { Link, Element } from 'react-scroll';
import './App.css';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import LandingPage from './components/LandingPage';
import { FaLaptopCode } from 'react-icons/fa';

function App() {
  const [offset, setOffset] = useState(-60);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setOffset(-130); // Adjust offset for smaller screens
      } else {
        setOffset(-60); // Default offset for larger screens
      }
    };

    // Set initial offset based on screen size
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <FaLaptopCode className="header-icon" />
          <span className="header-text">Ugrenovikj</span>
        </div>
        <nav className="nav-links">
          <Link to="home" href="#home" title='home' smooth duration={800} offset={offset}>Home</Link>
          <Link to="about" href="#about" title='about' smooth duration={800} offset={offset}>About</Link>
          <Link to="projects" href="#projects" title='projects' smooth duration={800} offset={offset}>Projects</Link>
          <Link to="contact" href="#contact" title='contact' smooth duration={800} offset={offset}>Contact</Link>
        </nav>
      </header>
      <main>
        <Element name="home" href="#home" title="Home" id="home" className="section LandingPage">
          <LandingPage />
        </Element>
        <Element name="about" href="#about" title="About" id="about" className="section About">
          <About />
        </Element>
        <Element name="projects" href="#projects" title="Projects" id="projects" className="section Projects">
          <Projects />
        </Element>
        <Element name="contact" href="#contact" title="Contact" id="contact" className="section Contact">
          <Contact />
        </Element>
      </main>
    </div>
  );
}

export default App;

import React from 'react';
import '../styles/LandingPage.css'; 
import myImage from '../assets/test2.jpeg'; 

function LandingPage() {
  return (
    <div className="LandingPage landing-page-image">
      <img src={myImage} alt="Me" title="Me" className="landing-image" />
      <h1>Welcome to My Portfolio</h1>
      <p>Hi, I'm Stefan Ugrenovikj, a passionate web developer with expertise in modern web technologies including Vue, Laravel, Node.js, and more. With experience across various projects, I aim to build responsive, user-friendly, and efficient applications. Dive into my portfolio to explore my work and the skills I've honed over the years</p>
    </div>
  );
}

export default LandingPage;
